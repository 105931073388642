<template>
  <div class="recipes">
    <h1 class="title has-text-centered">Rezepte</h1>
    <div class="recipe-margin container">
      <div
        v-for="recipe in recipes"
        class="card"
        :key="recipe.id"
        :model="recipe"
      >
        <div class="card-content">
          <p class="title is-5 mb-2">
            {{ recipe.name }}
            <font-awesome-icon
              v-if="recipe.is_bakery_product"
              :icon="['fas', 'cookie-bite']"
            />
          </p>
          <div class="mb-1">
            <span>{{ typeOfMeal[recipe.type_of_meal] }}</span>
            <font-awesome-icon class="ml-3" :icon="['fas', flavorIcons[recipe.flavor]]"/>
            <font-awesome-icon class="ml-3" :icon="['far', 'clock']"/>
            <span class="ml-1">{{ recipe.preparation_time }}</span>
            <span class="ml-1">Min.</span>
            <font-awesome-icon class="ml-3" :icon="['fas', 'user']"/>
            <font-awesome-icon
              class="ml-1"
              :icon="['far', 'square-minus']"
              @click="changePortions(-1, recipe)"
            />
            <span class="ml-1">{{ recipe.portions }}</span>
            <font-awesome-icon
              class="ml-1"
              :icon="['far', 'square-plus']"
              @click="changePortions(1, recipe)"
            />
          </div>
          <div class="media">
            <div class="media-left">
              <img
                class="image-meal"
                :src="require('@/assets/default-meal.png')"
                alt="Foto vom Gericht"
              >
            </div>
            <div class="media-content">
              <ul>
                <li
                  v-for="ingredientAmount in recipe.ingredient_amounts"
                >
                  {{ ingredientAmount.amount * recipe.portions }} 
                  {{ ingredientAmount.ingredient.unit }} 
                  {{ ingredientAmount.ingredient.name }}
                </li>
              </ul>
              <font-awesome-icon
                class="expand-icon"
                :icon="recipe.isExpanded ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                @click="recipe.isExpanded = !recipe.isExpanded"
              />
            </div>
          </div>
          <div v-if="recipe.isExpanded">
            <p>{{ recipe.isExpanded }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "@/main.js";

export default {
  name: 'Recipes',
  data() {
    return {
      ingredients: [],
      ingredientAmounts: [],
      recipes: [],
      flavorIcons: {
        'heartily': 'pizza-slice',
        'sweet': 'candy-cane',
        'hot': 'pepper-hot'
      },
      typeOfMeal: {
        'breakfast': 'Frühstück',
        'side-dish': 'Beilage',
        'main-dish': 'Hauptspeise',
        'dessert': 'Nachtisch'
      }
    }
  },
  mounted() {
    this.getIngredients()
    this.getRecipes()
  },
  methods: {
    getIngredients() {
      axios.get("/ingredients/").then((response) => {
        this.ingredients = response.data;
      });
    },
    getRecipes() {
      axios.get("/recipes/").then((response) => {
        this.recipes = response.data.map(obj => (
          { ...obj, isExpanded: false, portions: 2 }
        ))
      });
    },
    changePortions(value, recipe) {
      var newValue = recipe.portions + value
      if (newValue >= 1 && newValue <= 9) {
        recipe.portions = newValue
      }
    }
  }
}

</script>
<style scoped>

.card {
  background-color: silver;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.recipe-margin {
  margin: 5rem 0.5rem 0.5rem 0.5rem;
}

@media only screen and (min-width: 1024px) {
  .recipe-margin {
    margin-left: 25%;
    margin-right: 25%;
  }
}

.image-meal {
  border-radius: 0.25rem;
  width: 10rem;
  height: 10rem;
  border: 3px solid #363636;
}

.media {
  margin-bottom: -0.5rem;
}

.media-content {
  max-height: 10rem;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 6;
           line-clamp: 6;
   -webkit-box-orient: vertical;
}

.expand-icon {
  font-size: 1.2rem;
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
}


</style>